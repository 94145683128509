<template>
	<div class="position-relative">
		<div class="d-flex justify-content-between mb-3">
			<div class="mb-2">
				<h2 class="playfairDisplay mb-0 text-primary" v-text="title"></h2>
			</div>
			<div class="mb-2">
				<button type="button" v-on:click="getProductos()" title="Actualizar" class="btn btn-outline-primary me-2" :disabled="loadingProductos">
					<font-awesome-icon :icon="faRedo" />
				</button>
				<router-link :to="{name: 'admin.create'}" class="btn btn-primary">
					<font-awesome-icon :icon="faPlus" /> Agregar producto
				</router-link>
			</div>
		</div>
		
		<div class="card">
			<div class="card-body">
				<div v-if="(loadingProductos && !paginate) || productosFiltered.length<=0">
					<div v-if="(loadingProductos && !paginate)" class="text-muted text-center py-5">
						<div class="spinner-grow" role="status" style="width: 3rem; height: 3rem;">
							<span class="visually-hidden">Loading...</span>
						</div>
						<h2>
							Cargando...
						</h2>
					</div>
					<div v-else class="py-3">
						<div class="text-center mx-auto bg-light rounded-circle p-5" style="width: 200px; height: 200px;">
							<img :src="SVGEmpty" class="img-fluid">
						</div>
						<div class="text-center">
							<p>
								No se encontraron registros
							</p>
							<router-link :to="{name: 'admin.create'}" class="btn btn-primary">
								<font-awesome-icon :icon="faPlus" /> Agregar producto
							</router-link>
						</div>
					</div>
				</div>
				<div class="table-responsive" v-else>
					<table class="table table-hover">
						<thead>
							<th>Código</th>
							<th>Nombre</th>
							<th>Marca</th>
							<th>Proveedor</th>
							<th width="150" class="d-none d-lg-table-cell d-xl-table-cell">Fecha de actualización</th>
							<th width="150"></th>
						</thead>
						<tbody>
							<tr v-for="(producto, index) in productosFiltered" :key="index" :class="{'cursor-pointer':true, 'disabled':producto.ESTADO==2}" :title="producto.ESTADO==2 ? 'Inhabilitado' :''">
								<th>
									{{producto.CODIGO_PRODUCTO}}
								</th>
								<td>
									<span v-if="producto.adjuntos && producto.adjuntos.length>0" class="me-2 img-thumbnail">
										<img :src="producto.adjuntos[0].ARCHIVO" width="25" height="25" class="rounded-circle" style="object-fit: contain; object-position: center;">
									</span>
									{{producto.NOMBRE_PRODUCTO}}
								</td>
								<td>
									{{producto.DESCRIPCION_MARCA}}
								</td>
								<td class="text-center d-none d-lg-table-cell d-xl-table-cell">
									{{producto.NOMBRE_PROVEEDOR}}
								</td>
								<td class="text-center d-none d-lg-table-cell d-xl-table-cell">
									<span class="badge bg-dark">
										{{$filters.dateTime(producto.FECHA_ACTUALIZACION)}}
									</span>
								</td>
								<td align="right">
									<div class="table-options">
										<router-link :to="{name: 'admin.edit', params: {id: producto.CODIGO_PRODUCTO}}" class="btn btn-link me-1">
											<font-awesome-icon :icon="faEdit" />
										</router-link>
										
										<button v-on:click="celebraciones(producto.CODIGO_PRODUCTO)" title="Asignar celebraciones" class="btn btn-link" :disabled="loadingProductos">
											<font-awesome-icon :icon="faGifts" />
										</button>

										<DeleteButton class="btn btn-link" :codigo="producto.CODIGO_PRODUCTO" url="deleteProductos">
											<font-awesome-icon :icon="faTrash" />
										</DeleteButton>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<router-view />
		<infinite-scroll
			@infinite-scroll="infiniteHandler"
			:noResult="noResult"
			>
		</infinite-scroll>
		<div v-if="noResult && productosFiltered.length>0 && !loadingProductos" class="text-center text-muted">
			No hay más resultado
		</div>
	</div>
</template>
<script type="text/javascript">
import InfiniteScroll from 'infinite-loading-vue3'
import SVGEmpty from '@/assets/img/icons/no_data.svg'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import DeleteButton from '@/components/DeleteButton.vue'
import { faPlus, faFilter, faEdit, faTrash, faRedo, faGifts } from '@fortawesome/free-solid-svg-icons'
export default {
	components: {
		FontAwesomeIcon,
		InfiniteScroll,
		DeleteButton
	},
	data(){
		return {
			faPlus,
			faFilter,
			faEdit,
			faTrash,
			faRedo,
			faGifts,
			SVGEmpty,
			loadingProductos: false,
			noResult: false,
			paginate: false
		}
	},
	mounted(){
		this.getProductos()
	},
	methods: {
		infiniteHandler(){
			if (!this.noResult && !this.loadingProductos) {
				this.paginate = true
				this.getProductos()
			}
		},
		getProductos(){
			this.loadingProductos = true
			var filters = {}
			if (this.paginate) {
				filters.url = this.productos.next_page_url
			}
			this.$store.dispatch('getProductos', filters).then((res) => {
				if(res.data.data.length > 0 && this.productos.next_page_url != null){
					this.noResult = false
				}else{
					this.noResult = true
				}
				return res
			}).catch(err => {
				return err
			}).then(() => {
				this.loadingProductos = false
				this.paginate = false
			})
		},

		celebraciones(id){
			this.$router.push({name: 'admin.celebraciones', params: {id: id}})
		}
	},
	computed: {
		productos() {
			return this.$store.getters.productos || {}
		},
		productosFiltered() {
			return this.productos.data || []
		},
		title(){
			return this.$route.meta.title ? this.$route.meta.title : ''
		}
	}
}
</script>